import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

import 'App.css'

const App = React.lazy(() => import('./App'))

export const AppLoader = () => (
  <div className='app-loader'>
    {/* <img src={BgImage} /> */}
    <span />
    <p>お待ちください...</p>
  </div>
)

ReactDOM.render(
  <Suspense fallback={<AppLoader />}>
    <App />
  </Suspense>,
  document.getElementById('root')
)
